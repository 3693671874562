<template>
  <v-container>
    <base-material-card
      :title="$tc('appointment')"
      icon="mdi-calendar-multiselect"
      v-if="isMine"
    >
      <SendArchive
        :appointment="appointment"
        ref="send"
        @updated="fetchAppointment"
      ></SendArchive>
      <v-row v-if="$store.getters['auth/isLoggedIn']">
        <v-btn
          color="primary"
          @click.native="$router.back()"
          v-show="
            !$vuetify.breakpoint.xsOnly && appointment.status !== 'creating'
          "
          style="height: 30px"
          outlined
          class="ml-3"
        >
          <v-icon>mdi-arrow-left</v-icon>
          {{ $t("back") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click.native="$router.back()"
          v-if="appointment.status === 'creating'"
          style="height: 30px"
          outlined
          class="mr-3"
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          v-if="appointment.status === 'creating'"
          @click="savePreparation"
          elevation="0"
          color="primary"
          style="
            width: 100px !important;
            color: #363533;
            height: 30px;
            padding-top: 10px !important;
          "
        >
          <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
          {{ $t("save", { name: "" }) }}
        </v-btn>
        <v-dialog width="300" v-else v-model="dialog_actions">
          <template v-slot:activator="{ on }">
            <v-btn
              elevation="0"
              style="height: 30px"
              v-on="on"
              class="mr-4"
              :disabled="!active()"
            >
              {{ $t("actions") }}
            </v-btn>
          </template>
          <v-card>
            <div class="close">
              <v-btn icon @click="dialog_actions = false" small>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-card-title class="py-2">
              {{ $t("actions") }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <!-- <v-col cols="12">
                  <v-card
                    @click="
                      $refs.consentFormList.dialog = true;
                      dialog_actions = false;
                    "
                    :disabled="
                      appointment.available_dates.length === 0 ||
                        appointment.status === 'creating'
                    "
                    style="border: 1px solid var(--v-primary-base)"
                  >
                    <v-card-title class="py-2"
                      class="py-6 justify-center text-decoration-none text-center
                        d-block
                        text-uppercase"
                      style="
                        font-size: 18px !important;
                        text-decoration: none !important;
                        
                      "
                    >
                      {{ $t("consent-form.title") }}
                    </v-card-title>
                  </v-card>
                </v-col>
                <v-col cols="12" v-if="appointment.status !== 'done'">
                  <confirm-dialog
                    @update="updateTables"
                    :appointment="appointment"
                    block
                    :disabled="!appointment.tattooer || !can_edit"
                  />
                </v-col>-->
                <!--ALERTAS-->
                <v-col cols="12"><AlertList :appointment="appointment"/></v-col>
                <!--ENVIAR-->
                <v-col cols="12"
                  ><SendMail :hidden="false" :appointment="appointment"
                /></v-col>

                <!--<v-col cols="12">
                  <v-card
                    v-on="on"
                    @click="$refs.send.dialog = true"
                    style="border: 1px solid var(--v-primary-base)"
                  >
                    <v-card-title class="py-2"
                      class="py-6 justify-center  text-decoration-none text-center
                        d-block
                        text-uppercase"
                      style="
                        font-size: 18px !important;
                        text-decoration: none !important;
                      "
                    >
                      {{ $t("appointments.send_design_upload") }}
                    </v-card-title>
                  </v-card>
                </v-col>
                -->

                <v-col cols="12" v-if="$store.state.auth.user.id == 9 || true">
                  <Invoices :appointment="appointment" />
                </v-col>
                <v-col cols="12">
                  <base-border-card
                    :title="
                      $t(
                        canDelete
                          ? 'delete'
                          : appointment.status !== 'archived'
                          ? 'archivate'
                          : 'desarchivate',
                        { name: '' }
                      )
                    "
                    @card_clicked="del()"
                  >
                  </base-border-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" :order="$vuetify.breakpoint.smAndDown ? 2 : 1">
          <expenses-table
            outlined
            ref="expensesTableAppointment"
            type="appointment"
            :appointment="appointment"
            :title="$t('expenses.appointmentTable')"
            @update="updateTables"
            height="140px"
            v-if="
              $store.getters['auth/isLoggedIn'] &&
                appointment.status !== 'creating'
            "
            @open_price="$refs.prices.dialog = true"
          ></expenses-table>
          <session-prices
            v-if="$store.getters['auth/isLoggedIn']"
            ref="prices"
            @update="$emit('update')"
            :appointmentDates="appointmentDates"
            :appointment="appointment"
            :disabled="!can_edit"
            :hidden="true"
          ></session-prices>
          <customer-card
            v-if="appointment.customer"
            :customer="appointment.customer"
            :extra_customers="appointment.extra_customers"
            :studio="appointment.studio.studio_name"
            :appointment="appointment"
            @update="fetchAppointment"
            @asignate="assignateCustomer"
            :edit="can_edit_customer"
            class="mt-0"
            ><template>
              <tr
                v-if="
                  $store.getters['auth/isMainStudio'] &&
                    customer &&
                    customer.relations &&
                    customer.relations.length >= 1 &&
                    ((customer.relations[0].from === 'tattooer' &&
                      customer.relations[0].tattooer != null) ||
                      customer.relations[0].from === 'studio')
                "
              >
                <td>{{ $t("customers.contact") }}</td>
                <td>
                  {{ $tc(customer.relations[0].from)
                  }}<span
                    v-if="
                      customer.relations[0].from === 'tattooer' &&
                        customer.relations[0].tattooer != null
                    "
                    >: {{ customer.relations[0].tattooer.user.first_name }}
                    {{ customer.relations[0].tattooer.user.last_name }}</span
                  >
                </td>
              </tr>
              <tr v-if="appointment.availability">
                <td>{{ $t("appointments.availability_client") }}</td>
                <td>{{ appointment.availability }}</td>
              </tr>
            </template>
          </customer-card>

          <AsignateCustomer
            ref="asignatecustomer"
            :appointment="appointment"
            @update="fetchAppointment"
          />
          <tattooer-card
            class="mt-0"
            :tattooer="appointment.tattooer"
            :appointment="appointment"
            @update="fetchAppointment"
            :token_payment="commissionsPrice"
            v-if="
              $store.getters['auth/isStudio'] ||
                !$store.getters['auth/isLoggedIn']
            "
            @asignate="assignateTattooer"
            @calculate_commissions="$refs.design.calculateCommissions()"
            :extra_tattooers="appointment.extra_tattooers"
            ref="tattooer_card"
          />
          <AsignateTattooer
            v-if="$store.getters['auth/isStudio']"
            ref="asignate"
            :appointment="appointment"
            @update="fetchAppointment"
          />
          <studio-card
            class="mt-0"
            :studio="appointment.studio"
            :com="commissionsStudioPrice"
            v-if="
              $store.getters['auth/isTattooer'] ||
                !$store.getters['auth/isLoggedIn']
            "
          />
        </v-col>
        <v-col cols="12" md="6" :order="$vuetify.breakpoint.smAndDown ? 1 : 2">
          <v-card elevation="6" class="mt-0" id="appointment-card">
            <v-card-title class="py-3">
              <h2
                v-if="!$vuetify.breakpoint.xsOnly"
                style="text-transform: uppercase; font-weight: normal"
                v-text="$tc('appointment')"
              />

              <EditSession
                @update="fetchAppointment"
                :customers="appointment.extra_customers"
                :appointment="appointment"
                :session="nextDate"
                :hidden="true"
                ref="editSession"
              />
              <span
                class="pl-2 pl-md-4 white--text  pointer"
                v-if="nextDate"
                @click="$refs.editSession.edit()"
              >
                {{ $d(new Date(nextDate.date.substring(0, 10)), "dayMonth") }}
                - {{ nextDate.date.substring(11, 16) }}
              </span>
              <span v-else class="pl-2">
                <add-session
                  v-if="$store.getters['auth/isLoggedIn']"
                  class="pointer"
                  :text="true"
                  :disabled="!can_edit"
                  @update="fetchAppointment"
                  :appointment="appointment"
                  :customers="appointment.extra_customers"
                />
              </span>
              <span
                class="pl-2"
                v-bind:style="[{ color: statusAppointmentColor }]"
              >
                {{ $t("appointments.states." + appointment.state.text) }}
              </span>
              <v-dialog
                width="400"
                v-model="dialogURL"
                v-if="$store.getters['auth/isLoggedIn']"
              >
                <template v-slot:activator="{ on: onDialog }">
                  <v-tooltip v-show="$vuetify.breakpoint.lgAndUp" bottom>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        small
                        icon
                        v-on="{ ...onDialog, ...onTooltip }"
                        style="position: absolute; right: 50px"
                      >
                        <v-icon small>mdi-link-variant</v-icon></v-btn
                      >
                    </template>
                    {{ $t("appointments.client_link") }}
                  </v-tooltip>
                  <v-btn
                    v-show="$vuetify.breakpoint.mdAndDown"
                    small
                    icon
                    v-on="{ ...onDialog }"
                    style="position: absolute; right: 50px"
                  >
                    <v-icon small>mdi-link-variant</v-icon></v-btn
                  >
                </template>
                <v-card id="consentFormLink">
                  <v-card-title
                    class="py-3"
                    style="
                      text-decoration: underline;
                      text-transform: uppercase;
                    "
                  >
                    Vista del cliente:
                  </v-card-title>
                  <div class="close">
                    <v-btn icon @click="dialogURL = false">
                      <v-icon id="iconClose">mdi-close</v-icon>
                    </v-btn>
                  </div>
                  <v-card-text class="pb-2">
                    <v-text-field
                      outlined
                      dense
                      id="URL2"
                      readonly
                      v-model="URL2"
                    >
                      <template slot="prepend-inner">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon class="mr-2" v-on="on" @click="copy2()">
                              mdi-content-copy
                            </v-icon>
                          </template>
                          {{ $t("copy") }}
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-card-text>
                  <v-card-actions class="pt-0">
                    <v-spacer></v-spacer>
                    <v-btn
                      type="submit"
                      style="
                        width: 100px !important;
                        color: #363533;
                        height: 25px;
                        padding-top: 10px !important;
                      "
                      @click="openSend()"
                      elevation="0"
                    >
                      <v-icon style="margin-right: 5px" size="14px"
                        >$send</v-icon
                      >
                      {{ $t("enviar", { name: $t("") }) }}
                    </v-btn>
                    <SendMail
                      ref="sendMail"
                      hidden
                      :appointment="appointment"
                    />
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                width="500"
                v-model="dialog"
                v-if="$store.getters['auth/isLoggedIn']"
              >
                <template v-slot:activator="{ on: onDialog }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        icon
                        style="position: absolute; right: 25px"
                        v-on="{ ...onTooltip, ...onDialog }"
                        @click="openEdit"
                        small
                        :disabled="!can_edit || !active()"
                      >
                        <v-icon small color="primary"> $edit </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("edit", { name: "" }) }}</span>
                  </v-tooltip>
                </template>
                <v-card id="editAppointment">
                  <v-card-title class="py-2"
                    ><u style="text-transform: uppercase">{{
                      $t("edit_appointment")
                    }}</u>
                  </v-card-title>
                  <div class="close">
                    <v-btn icon @click="dialog = false" small>
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                  <form>
                    <v-card-text>
                      <v-row align="center">
                        <v-col cols="12" class="py-0">
                          <label>
                            {{ $t("tittle") }}
                          </label>
                          <v-text-field
                            v-model="editAppointment.title"
                            outlined
                            hide-details
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0" v-if="nextDate">
                          <label>
                            {{ $t("appointments.next_session") }}
                          </label>
                          <v-btn
                            @click="$refs.editSession.edit()"
                            elevation="0"
                            outlined
                            block
                            height="32px"
                            class="mt-1"
                          >
                            <span class="white--text">
                              {{
                                $d(
                                  new Date(nextDate.date.substring(0, 10)),
                                  "dayMonth"
                                )
                              }}
                              - {{ nextDate.date.substring(11, 16) }}
                            </span>
                          </v-btn>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0" v-else>
                          <label>
                            {{ $t("appointments.next_session") }}
                          </label>
                          <add-session
                            v-if="$store.getters['auth/isLoggedIn']"
                            class="pointer"
                            :text="true"
                            :block="true"
                            :disabled="!can_edit"
                            @update="fetchAppointment"
                            :appointment="appointment"
                            :customers="appointment.extra_customers"
                          />
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <label
                            v-text="$tc('appointments.type_appointment')"
                          />
                          <v-select
                            clearable
                            v-model="editAppointment.type"
                            outlined
                            dense
                            hide-details
                            :items="types"
                            :item-text="
                              (x) => $tc('appointments.type.' + x.name)
                            "
                            item-value="name"
                          ></v-select>
                        </v-col>

                        <v-col cols="12" md="6" class="py-0">
                          <label v-text="$tc('appointments.from')" />
                          <v-select
                            clearable
                            v-model="editAppointment.from"
                            outlined
                            dense
                            hide-details
                            :items="commissions"
                          ></v-select>
                        </v-col>
                        <v-col sm="6" cols="12" class="py-0">
                          <label>{{ $t("appointments.body_part") }}</label>
                          <v-dialog
                            v-if="appointment.type != 'microblading'"
                            v-model="dialogBodySelector"
                            width="700"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="editAppointment.body_part"
                                outlined
                                hide-details
                                dense
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-card id="bodySelector">
                              <v-card-title class="py-2">
                                {{
                                  $t("edit", {
                                    name: $tc("appointments.body_part"),
                                  })
                                }}
                              </v-card-title>
                              <div class="close">
                                <v-btn
                                  icon
                                  @click="dialogBodySelector = false"
                                  small
                                >
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </div>
                              <v-card-text>
                                <BodySelector
                                  v-if="appointment.type == 'tattooer'"
                                  @selected="partSelected"
                                />
                                <BodySelectorPiercings
                                  v-else-if="
                                    appointment.type == 'piercer' ||
                                      appointment.type == 'laser'
                                  "
                                  @head="
                                    change = true;
                                    customer.body_part = null;
                                  "
                                  :change="change"
                                  @selected="partSelected"
                                />
                                <FaceSelectorPiercings
                                  @body="
                                    change = false;
                                    customer.body_part = null;
                                  "
                                  :change="change"
                                  ref="body_selector"
                                  @selected="partSelected"
                                />
                              </v-card-text>
                            </v-card>
                          </v-dialog>
                          <v-text-field
                            v-else
                            v-model="editAppointment.body_part"
                            outlined
                            hide-details
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col sm="6" cols="12" class="py-0" v-if="false">
                          <label>{{ $t("appointments.how_meet_show") }}</label>
                          <v-text-field
                            v-model="editAppointment.how_meet"
                            outlined
                            hide-details
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <label>{{ $t("appointments.description") }}</label>
                          <v-textarea
                            outlined
                            v-model="editAppointment.description"
                            :hint="$t('appointments.description_hint')"
                            hide-details
                            rows="3"
                            style="border-radius: 10px"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        outlined
                        @click="
                          dialog = false;
                          fetchAppointment();
                        "
                        style="height: 25px; width: 100px"
                        elevation="0"
                        >{{ $t("cancel") }}</v-btn
                      >
                      <v-btn
                        style="
                          width: 100px !important;
                          color: #363533;
                          height: 25px;
                          padding-top: 10px !important;
                        "
                        elevation="0"
                        @click="update"
                      >
                        <v-icon style="margin-right: 5px" size="14px"
                          >$save</v-icon
                        >
                        {{ $t("save", { name: $t("") }) }}
                      </v-btn>
                    </v-card-actions>
                  </form>
                </v-card>
              </v-dialog>
            </v-card-title>
            <v-divider></v-divider>
            <v-simple-table
              :id="$vuetify.breakpoint.xsOnly ? 'tableSimple' : ''"
            >
              <tr v-if="appointment.title">
                <td width="300">{{ $t("tittle") }}</td>
                <td
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 180px;
                  "
                >
                  {{ appointment.title }}
                </td>
              </tr>
              <tr v-if="$store.getters['auth/isLoggedIn']">
                <td width="300" height="36">
                  {{ $tc("appointments.type_appointment") }}
                </td>
                <td>
                  <span v-if="!appointment.type" style="color: red !important">
                    {{ $t("appointments.states.pending") }}
                  </span>
                  <span v-else>
                    {{ $tc("appointments.type." + appointment.type) }}
                    <span v-if="appointment.sub_type" class="font-italic">
                      {{ appointment.sub_type }}
                    </span>
                  </span>
                </td>
              </tr>
              <tr v-if="$store.getters['auth/isLoggedIn']">
                <td height="36">{{ $t("appointments.from") }}</td>
                <td>
                  {{
                    $tc(
                      appointment.from === "tattooer"
                        ? "artist"
                        : appointment.from
                    )
                  }}
                  <span v-if="appointment.walk_in" class="font-italic">
                    Walk-In
                  </span>
                </td>
              </tr>
              <tr v-if="appointment.tattootype">
                <td height="36">{{ $t("appointments.tattootype") }}</td>
                <td>{{ appointment.tattootype.name }}</td>
              </tr>
              <tr>
                <td width="300px" height="36">
                  {{ $t("appointments.body_part") }}
                </td>
                <td style="text-transform: capitalize">
                  {{ appointment.body_part || "-" }}
                </td>
              </tr>
              <tr v-if="$store.getters['auth/isLoggedIn'] && false">
                <td width="300">{{ $t("tattooers.state") }}</td>
                <td v-bind:style="[{ color: statusAppointment.color }]">
                  {{ $t(statusAppointment.text) }}
                </td>
              </tr>
              <tr>
                <td>
                  {{
                    $tc(
                      $vuetify.breakpoint.xsOnly
                        ? "appointments.sessions.dates"
                        : box_active
                        ? "appointments.sessions.next_box"
                        : "appointments.sessions.next"
                    )
                  }}
                </td>
                <td>
                  <add-session
                    v-if="$store.getters['auth/isLoggedIn']"
                    class=""
                    :disabled="!can_edit || !active()"
                    @update="fetchAppointment"
                    :appointment="appointment"
                    :customers="appointment.extra_customers"
                  />
                  <LaborExangeDayAssaing
                    :icon="true"
                    v-else-if="false"
                    @update="fetchAppointment"
                    :disabled_prop="!can_edit || !active()"
                    :appointment="appointment"
                  />
                  <session-list
                    @update="fetchAppointment"
                    :style="
                      !$store.getters['auth/isLoggedIn']
                        ? 'margin-left: 18px !important'
                        : ''
                    "
                    :disabled="!can_edit || !active()"
                    :appointmentDates="appointmentDates"
                    :appointment="appointment"
                    :customers="appointment.extra_customers"
                  ></session-list>
                </td>
              </tr>
              <tr>
                <td>
                  {{
                    $t(
                      $vuetify.breakpoint.xsOnly
                        ? "consent-form.title_xs"
                        : "consent-form.title"
                    )
                  }}
                </td>
                <td>
                  <AddExtraCustomerConsentForm
                    :appointment="appointment"
                    @update="
                      fetchAppointment();
                      this.$response.consentFormList.dialog = false;
                    "
                    v-if="$store.getters['auth/isLoggedIn']"
                    :mini="true"
                    :disabled="!active()"
                  />
                  <ConsentFormList
                    @update="fetchAppointment"
                    :style="
                      !$store.getters['auth/isLoggedIn']
                        ? 'margin-left: 18px !important'
                        : ''
                    "
                    :disabled="!can_edit || !active()"
                    :appointmentDates="appointmentDates"
                    :appointment="appointment"
                    :customers="appointment.extra_customers"
                    ref="consentFormList"
                  />
                </td>
              </tr>
              <tr v-if="$store.getters['auth/isLoggedIn'] && false">
                <td>{{ $t("how_did_he_met_us") }}</td>
                <td>
                  {{ appointment.how_meet || "-" }}
                </td>
              </tr>
              <tr v-if="$store.getters['auth/isLoggedIn']">
                <td>
                  {{
                    $t(
                      $vuetify.breakpoint.xsOnly
                        ? "appointments.intern_file_xs"
                        : "appointments.intern_file"
                    )
                  }}
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        small
                        icon
                        @click="$refs.share.dialog = true"
                        :disabled="appointment.status === 'creating'"
                      >
                        <v-icon small>mdi-plus-circle</v-icon></v-btn
                      >
                    </template>
                    {{ $t("appointments.share_archive") }}
                  </v-tooltip>
                  <v-btn
                    small
                    icon
                    @click="goToFiles"
                    :disabled="appointment.status === 'creating'"
                  >
                    <v-icon small>$eye</v-icon></v-btn
                  >
                  <ShareArchive
                    :appointment="appointment"
                    ref="share"
                    @update="goToFiles"
                  ></ShareArchive>
                </td>
              </tr>
            </v-simple-table>

            <v-simple-table>
              <tr v-if="$store.getters['auth/isLoggedIn']">
                <td style="vertical-align: baseline">
                  {{ $t("appointments.description") }}
                </td>
                <td>
                  <v-textarea
                    v-bind:class="[{ disabled: !description_edit }]"
                    outlined
                    v-model="editAppointment.description"
                    hide-details
                    :rows="
                      editAppointment.description &&
                      editAppointment.description.length > 1000
                        ? 3
                        : 2
                    "
                    style="border-radius: 10px"
                    @blur="update"
                    @click="smallEdit"
                  ></v-textarea>
                  <span v-if="false" @click="smallEdit">
                    {{
                      appointment.description &&
                      appointment.description.length > 50 &&
                      short
                        ? appointment.description.substring(0, 50) + "..."
                        : appointment.description || "-"
                    }}<br />
                  </span>
                  <a
                    @click.stop="short = !short"
                    v-if="
                      appointment.description &&
                        appointment.description.length > 50 &&
                        !description_edit &&
                        false
                    "
                    ><u>{{ short ? $t("read_more") : $t("read_less") }}</u></a
                  >
                </td>
              </tr>
            </v-simple-table>

            <h4
              v-show="
                $vuetify.breakpoint.smAndDown &&
                  appointment.sugestionsURL.length > 0
              "
            >
              Propuestas cliente
            </h4>
            <photo-gallery
              :photos="appointment.sugestionsURL"
              numOfCols="4"
              title="Propuestas cliente"
            />
            <h5
              class="pa-2"
              v-show="
                $vuetify.breakpoint.smAndDown &&
                  appointment.designURL.length > 0
              "
            >
              Diseño artista
            </h5>
            <photo-gallery
              :photos="appointment.designURL"
              numOfCols="4"
              title="Diseño artista"
            />
          </v-card>
          <design-card
            class="mt-0"
            :design="appointment.design"
            :appointment_id="appointment.id"
            :appointment="appointment"
            :appointmentDates="appointmentDates"
            :tattooer_id="appointment.tattooer_id"
            :token_payment="appointment.token_payment"
            @update="fetchAppointment"
            :can_edit="can_edit && active()"
            ref="design"
          ></design-card>
          <v-card
            v-if="
              $store.getters['auth/isStudio'] &&
                appointment.status !== 'creating'
            "
          >
            <v-card-title class="py-3">
              <h2
                style="text-transform: uppercase; font-weight: normal"
                v-text="$tc('inventory')"
              />
              <FormMove
                title="appointment"
                :card="true"
                :appointment_id="appointment.id"
                @update="reloadInventory()"
              />
            </v-card-title>

            <v-divider></v-divider>

            <base-data-table-cards
              id="inventory_appointment"
              :headers="inventoryHeaders"
              :headers_small="headers_small"
              :data="itemsInventory"
              :server-items-length="totalInventory"
              :items-per-page="5"
              :title="$t('inventory')"
              dense
              :totalItems="totalInventory"
              class="px-2"
            >
              <template v-slot:item_invoice_number="item">
                <UploadPayroll :movement="item" />
              </template>
              <template v-slot:item_cantidad="item">
                <span
                  v-bind:class="[
                    {
                      'red--text': item.cantidad < 0,
                      'success--text': item.cantidad > 0,
                    },
                  ]"
                  >{{ item.cantidad }}</span
                >
              </template>
              <template v-slot:item_data="item">
                <span>{{ $d(new Date(item.data), "dateShort") }}</span>
              </template>
              <template v-slot:item_total="item">
                {{ item.total ? $n(item.total, "currency") : "-" }}
              </template>
            </base-data-table-cards>
          </v-card>
        </v-col>
      </v-row>
    </base-material-card>
    <base-material-card
      :title="$tc('appointment')"
      icon="mdi-calendar-multiselect"
      v-else-if="appointment"
    >
      <v-row justify="center">
        <v-card>
          <v-card-title class="py-3">
            <v-row class="ma-2">
              <v-col>
                <v-row justify="center">
                  <v-col style="text-align: center">
                    {{ $t("appointments.not_yours") }}
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-btn
                    color="primary"
                    @click.native="$router.back()"
                    style="height: 30px"
                    outlined
                    class="ml-3"
                  >
                    <v-icon>mdi-arrow-left</v-icon>
                    {{ $t("back") }}
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-row>
    </base-material-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "AppointmentView",
  data() {
    return {
      headers_small: [
        { value: "invoice_number", cols: 1 },
        { value: "data" },
        { value: "cantidad", cols: 1 },
        { value: "total", cols: 4 },
      ],
      original_from: null,
      change: false,
      description_edit: false,
      appointment: null,
      customer: {},
      editAppointment: {},
      short: true,
      isMounted: false,
      dialog: false,
      dialogBodySelector: false,
      dialogURL: false,
      dialog_actions: false,
      dialogConsentForm: false,
      today: null,
      itemsInventory: [],
      totalInventory: null,
      options_inventory: {},
      inventoryHeaders: [
        {
          text: this.$t("inventorys.invoice_n"),
          align: "center",
          sortable: false,
          value: "invoice_number",
        },
        {
          text: this.$t("inventorymovement.data"),
          align: "center",
          sortable: false,
          value: "data",
        },
        {
          text: this.$t("inventorys.product_cody"),
          align: "center",
          sortable: false,
          value: "inventory.product_cody",
        },
        {
          text: this.$t("product.quantity"),
          align: "center",
          sortable: false,
          value: "cantidad",
        },
        {
          text: this.$t("tattooers.price"),
          align: "center",
          sortable: false,
          value: "total",
        },
      ],
      commissions: [
        {
          text: this.$tc("studio"),
          value: "studio",
        },
        {
          text: this.$tc("artist"),
          value: "tattooer",
        },
      ],
    };
  },
  components: {
    FormMove: () => import("@/components/inventorymovement/FormMove"),
    UploadPayroll: () => import("@/components/inventorymovement/UploadPayroll"),
    StudioCard: () => import("@/components/studio/Card"),
    TattooerCard: () => import("@/components/tattooer/Card"),
    AsignateTattooer: () => import("@/components/tattooer/AsignateTattooer"),
    AsignateCustomer: () => import("@/components/customer/AsignateCustomer"),
    DesignDialog: () => import("@/components/appointments/design/Dialog"),
    DesignDialogToken: () =>
      import("@/components/appointments/design/DialogTokenPayment"),
    ConfirmDialog: () =>
      import("@/components/appointments/ConfirmAppointmentDialog"),
    AddSession: () => import("@/components/appointments/sessions/Add"),
    SessionList: () => import("@/components/appointments/sessions/List"),
    ConsentFormList: () =>
      import("@/components/appointments/sessions/ListConsentForm"),
    SessionPrices: () => import("@/components/appointments/sessions/Prices"),
    DesignCard: () => import("@/components/appointments/design/Card"),
    ShareArchive: () => import("@/components/appointments/ShareArchive"),
    SendArchive: () => import("@/components/appointments/SendArchive"),
    PhotoGallery: () => import("@/components/ui/ImageGalery"),
    ExpensesTable: () => import("@/components/expense/Table"),
    CustomerCard: () => import("@/components/customer/Card"),
    TokenPayments: () =>
      import("@/components/user/settings/profile/TokenPayment"),
    EditDialog: () => import("@/components/appointments/design/EditDialog"),
    EditDialogToken: () =>
      import("@/components/appointments/design/EditDialogTokenPayment"),
    LaborExangeDayAssaing: () =>
      import("@/components/appointments/LaborExangeDayAssaing"),
    EditSession: () => import("@/components/appointments/sessions/Edit"),
    AddExtraCustomerConsentForm: () =>
      import("@/components/customer/AddExtraCustomerConsentForm"),
    Invoices: () => import("@/components/invoices/InvoicesAppointment"),
    BodySelector: () => import("@/components/ui/BodySelector"),
    BodySelectorPiercings: () =>
      import("@/components/ui/BodySelectorPiercings"),
    FaceSelectorPiercings: () =>
      import("@/components/ui/FaceSelectorPiercings"),
    AlertList: () => import("@/components/alert/AlertsList"),
    SendMail: () => import("@/components/mails/SendMail"),
  },
  mounted() {
    this.fetchAppointment();
    this.fetchAllIssues();
    this.fetchCustomer();
    this.today = new Date();
    this.isMounted = true;
  },
  watch: {
    options_inventory: {
      handler() {
        this.fetchAllIssues();
      },
      deep: true,
    },
  },
  computed: {
    canDelete() {
      let a = this.appointment;
      if (a.payed) return false;
      let can = true;
      if (a.available_dates)
        a.available_dates.forEach((d) => {
          console.log("d", d);
          d.expenses.forEach((e) => {
            console.log("e", e);
            if (e.stripe_token || e.pending == false) can = false;
          });
        });

      return can;
    },
    statusAppointment() {
      switch (this.appointment.status) {
        case "help":
          return {
            text: "appointments.states.help",
            color: "red",
            delete: true,
          };
        case "archived":
          return {
            text: "appointments.states.archived",
            color: "var(--v-gris1-base)",
            delete: false,
          };
        case "done": {
          return {
            text: "appointments.states.done",
            color: "var(--v-green1-base)",
            delete: null,
          };
        }
        default: {
          if (
            this.appointment.available_dates.find(
              (x) =>
                x.type === "appointment_day" ||
                x.type === "appointment_day_session"
            )
          ) {
            return {
              text: "appointments.states.assigned",
              color: "var(--v-secondary-base)",
              delete: true,
            };
          } else {
            return {
              text: "appointments.states.pending",
              color: "orange",
              delete: true,
            };
          }
        }
      }
    },
    statusAppointmentColor() {
      console.log("STATUS", this.appointment.state.text);
      if (this.appointment)
        switch (this.appointment.state.text) {
          case "request":
            return "var(--v-primary-base)";
          case "pending":
            return "orange";
          case "assigned":
            return "var(--v-secondary-base)";
          case "done":
          case "archived":
            return "var(--v-gris1-base)";
          case "help":
            return "red";
          case "check_out":
            return "grey";
        }
    },
    limitations() {
      if (this.appointment) {
        let ts = this.appointment.tattooer.tattooer_studios.find(
          (ts) =>
            ts.tattooer_id === this.appointment.tattooer_id &&
            ts.studio_id === this.appointment.studio_id
        );
        if (ts && ts.limitations) {
          return ts.limitations;
        }
      }
    },
    can_edit() {
      if (this.$store.getters["auth/isTattooer"]) {
        if (this.limitations) {
          return this.limitations.appointment_edit;
        }
      }
      return true;
    },
    can_edit_customer() {
      if (this.$store.getters["auth/isTattooer"]) {
        if (this.limitations) {
          return this.limitations.customer_edit;
        }
      }
      return true;
    },
    payment_appointment() {
      let s = this.appointment.studio.user.setting.find(
        (x) => x.key === "payment_appointment"
      );
      if (s) return s.value === "1";
      return false;
    },
    box_active() {
      let s = this.appointment.studio.user.setting.find(
        (x) => x.key === "box_active"
      );
      if (s) return s.value === "1" && this.appointment.studio.user.plan.boxes;
      return false;
    },
    isMine() {
      if (this.appointment) {
        if (this.appointment.status === "relation") return false;
        if (this.$store.getters["auth/isTattooer"])
          return (
            this.appointment.tattooer_id ===
            this.$store.state.auth.user.tattooer.id
          );
        if (this.$store.getters["auth/isStudio"])
          return (
            this.appointment.studio_id === this.$store.state.auth.user.studio.id
          );
        if (!this.$store.getters["auth/isLogged"]) return true;
      }
      return false;
    },
    types() {
      let s = [];
      let setting = this.appointment.studio.user.setting.find(
        (st) => st.key === "studio_services"
      );
      if (setting) s = JSON.parse(setting.value);
      if (this.appointment.tattooer)
        s = this.appointment.tattooer.type_artist.filter((x) => {
          let st = s.find((y) => y.id === x.id);
          if (st) return true;
          return false;
        });
      return s;
    },
    URL() {
      let url = `${window.location.origin}/?#/inscription/appointment/${this.appointment.id}/consent`;
      return url;
    },
    URL2() {
      let url = `${window.location.origin}/?#/cliente/${this.appointment.customer_id}/appointment/${this.appointment.id}`;
      if (this.appointment.status == "pre_inscription")
        url = `${window.location.origin}/?#/inscription/appointment/${this.appointment.id}/post-form`;
      return url;
    },
    totalPrice() {
      let total = 0;
      this.appointmentDates.forEach((x) => (total += +x.sesion_price));
      return total;
    },

    commissionsPrice() {
      let total = 0;
      this.appointmentDates.forEach((x) => (total += +x.sesion_commision));
      return total;
    },
    commissionsStudioPrice() {
      let total = 0;
      this.appointmentDates.forEach(
        (x) => (total += +x.sesion_price - x.sesion_commision)
      );
      return total;
    },
    nextDate() {
      let dates = this.appointmentDates.filter((x) => {
        let date = new Date(x.date);
        return date.getTime() >= this.today.getTime();
      });
      //console.log("date", dates[0]);
      if (dates.length) return dates[0];
      else return this.appointmentDates[this.appointmentDates.length - 1];
    },
    appointmentDates() {
      if (this.appointment.available_dates) {
        let dates = this.appointment.available_dates
          .filter(
            (x) =>
              x.type === "appointment_day" ||
              x.type === "appointment_day_session"
          )
          .map((s) => {
            let c = { ...s };
            if (c.customers.length === 0)
              c.customers = [
                {
                  ...this.appointment.customer,
                  pivot: {
                    state: this.appointment.consent_form_state,
                    file: this.appointment.consentFormUrl,
                  },
                },
              ];
            return c;
          })
          .sort((x, y) => {
            x = new Date(x.date);
            y = new Date(y.date);
            if (x.getTime() < y.getTime()) return -1;
            if (x.getTime() === y.getTime()) return 0;
            if (x.getTime() > y.getTime()) return +1;
          });
        return dates;
      }
      return [];
    },
  },
  methods: {
    partSelected(body_part) {
      //Guardar body part a editAppointment i tanco el dialog
      this.editAppointment.body_part = body_part;
      this.dialogBodySelector = false;
    },
    active() {
      if (this.isMounted)
        if (this.$refs.tattooer_card) return this.$refs.tattooer_card.active;
      return true;
    },
    ...mapActions("inventorymovements", [
      "getInventorymovements",
      "delInventorymovements",
    ]),
    del() {
      this.$confirm(
        this.$t(
          this.canDelete
            ? "delete_ask"
            : this.appointment.status === "archived"
            ? "desarchived_ask"
            : "archived_ask"
        )
      ).then(() => {
        this.deleteOrArchivate({
          id: this.appointment.id,
          action: this.canDelete
            ? "delete"
            : this.appointment.status === "archived"
            ? "dearchivate"
            : "archivate",
        }).then((data) => {
          this.$alert(
            this.$t(
              this.canDelete
                ? "delete_ok"
                : this.appointment.status === "archived"
                ? "desarchived_ok"
                : "archived_ok"
            )
          );

          this.$router.back();
        });
      });
    },
    fetchAllIssues() {
      this.getInventorymovements({
        pagination: this.options_inventory,
        filters: { appointment_id: this.$route.params.id },
      }).then((data) => {
        console.log(data);
        this.itemsInventory = data.data;
        this.totalInventory = data.total;
      });
    },
    assignateCustomer(customer) {
      this.$refs.asignatecustomer.setCustomer(customer.id);
    },
    assignateTattooer(tattooer) {
      this.$refs.asignate.setTattooer(tattooer ? tattooer.id : null);
    },
    reloadInventory() {
      this.fetchAllIssues();
      this.$refs.expensesTableAppointment.fetchExpenses();
      this.$refs.expensesTableAppointment.fetchAmount();
    },
    ...mapActions("appointments", [
      "getAppointment",
      "updateAppointment",
      "internalFormSave",
      "deleteOrArchivate",
      "markAppointment",
    ]),
    savePreparation() {
      this.internalFormSave(this.$route.params.id).then((response) => {
        if (response.success) this.$router.back();
      });
    },
    ...mapActions("customers", ["getCustomer"]),
    fetchAppointment() {
      this.getAppointment(this.$route.params.id).then((appointment) => {
        if (appointment.body_part)
          appointment.body_part = this.$t(
            appointment.body_part.replace("_", " ")
          );
        this.appointment = appointment;
        //console.log(this.appointment);
        this.fetchCustomer(this.appointment.customer_id);
        setTimeout(() => {
          this.$refs.expensesTableAppointment.fetchExpenses();
          this.$refs.expensesTableAppointment.fetchAmount();
          if (this.$refs.tattooer_card)
            this.$refs.tattooer_card.fetchCommision();
        }, 1000);
        console.log(this.appointment);
        console.log(
          !appointment.studio_opened && this.$store.getters["auth/isStudio"],
          "s"
        );
        console.log(
          !appointment.tattooer_opened &&
            this.$store.getters["auth/isTattooer"],
          "t"
        );
        console.log(this.isMine, "mine");
        if (
          ((!appointment.studio_opened &&
            this.$store.getters["auth/isStudio"]) ||
            (!appointment.tattooer_opened &&
              this.$store.getters["auth/isTattooer"])) &&
          this.isMine
        )
          this.markAppointment(this.$route.params.id);
        this.editAppointment = this.appointment;
        this.original_from = this.appointment.from;
      });
    },

    fetchCustomer(value) {
      this.getCustomer(value).then((customer) => {
        this.customer = customer;
        console.log(this.customer);
      });
    },
    goToFiles() {
      let cus_name = `Cliente ${this.appointment.customer_id}`;
      if (this.appointment.customer.user.first_name) {
        cus_name = this.appointment.customer.user.first_name;
        if (this.appointment.customer.user.last_name) {
          cus_name += " " + this.appointment.customer.user.last_name;
        }
      }
      if (this.$store.getters["auth/isTattooer"]) {
        this.$router.push({
          name: "filesView",
          params: {
            sub_route: `${this.appointment.studio.studio_name}/${cus_name}`,
          },
        });
      } else if (this.$store.getters["auth/isStudio"]) {
        let t = "Asesoramiento";
        if (this.appointment.tattooer) {
          t = `${this.appointment.tattooer.user.first_name}`;
          if (this.appointment.tattooer.user.last_name)
            t += ` ${this.appointment.tattooer.user.last_name}`;
        }
        this.$router.push({
          name: "filesView",
          params: {
            sub_route: `${t}/${cus_name}`,
          },
        });
      }
    },
    goToConsent() {
      if (!this.appointment.type) {
        this.$alert(this.$t("appointments.type_empty_consent"), "", "");
      } else {
        switch (this.appointment.type) {
          case "tattooer":
            this.$router.push({
              name: "ConsentFormView",
              params: { id: this.$route.params.id },
            });
            break;
          case "piercer":
            this.$router.push({
              name: "ConsentFormPiercerView",
              params: { id: this.$route.params.id },
            });
            break;
          case "microblading":
            this.$router.push({
              name: "ConsentFormMicroblandingView",
              params: { id: this.$route.params.id },
            });
            break;
          case "laser":
            this.$router.push({
              name: "ConsentFormLaserView",
              params: { id: this.$route.params.id },
            });
            break;
        }
      }
    },
    save(data) {
      //console.log(data);
      this.appointment = data;
    },
    copy() {
      /* Get the text field */
      var copyText = document.getElementById("URL");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");
      this.dialogConsentForm = false;
      this.$alert(this.$t("link_copy_success"), "", "");
    },
    copy2() {
      /* Get the text field */
      var copyText = document.getElementById("URL2");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");
      this.dialogURL = false;
      this.$alert(this.$t("link_copy_success"), "", "");
    },
    openEdit() {
      this.editAppointment = this.appointment;
      this.dialog = true;
    },

    openSend() {
      console.log("OPEN SEND");
      this.$refs.sendMail.open("CITA");
    },
    smallEdit() {
      this.editAppointment = this.appointment;
      this.description_edit = true;
    },
    update() {
      let formData = {
        title: this.editAppointment.title,
        description: this.editAppointment.description,
        body_part: this.editAppointment.body_part,
        from: this.editAppointment.from,
        price: this.editAppointment.price,
        how_meet: this.editAppointment.how_meet,
        type: this.editAppointment.type,
      };
      this.updateAppointment({
        appointment_id: this.$route.params.id,
        formData,
      }).then((data) => {
        if (this.dialog) this.$alert(this.$t("save_ok"), "", "");
        this.fetchAppointment();
        this.description_edit = false;
        this.dialog = false;
        if (this.editAppointment.from !== this.original_from)
          this.$confirm(
            " Si cambias la procedencia, los porcentajes económicos se recalcularán."
          ).then(() => {
            this.$refs.design.calculateCommissions();
          });
      });
    },
    updateTables() {
      this.fetchAppointment();
    },
  },
};
</script>

<style scoped lang="sass">
.v-data-table
td
  padding: 3px 8px !important
  white-space: nowrap
  &.pl-4
    padding-left: 16px !important
td:first-child
  color: var(--v-gris1-base)
@media (min-width:970px) and (max-width: 1200px)
  td:last-child
    min-width: 150px !important

#tableSimple
td:last-child
  min-width: 150px
h2
  font-weight: normal
  font-size: 20px
h5
  font-weight: normal
  font-size: 14px
  color: var(--v-gris1-base)

label
  display: block
  height: 25px
</style>
<style lang="sass">
#consentFormLink
.close .v-btn
  top: 0
  right: 0px
  position: absolute

  #iconClose
    color: var(--v-primary-base)
    position: absolute
.pending
  color: orange !important
#editAppointment

.v-input.v-textarea
  fieldset
    margin-top: 3px

.selectred
  color: red

#inventory_appointment
  .v-data-table__wrapper
    overflow: hidden
    td, th
      white-space: nowrap

#appointment-card
  .v-input
    &.disabled
      fieldset
        border-color: rgba(255, 255, 255, 0.5) !important
</style>
